<template>
    <div class="d-flex flex-column slide" ref="teaser">
        <div class="align-self-stretch slide__image">
            <NuxtLink :to="slide?.link?.to || '#'" @click="handleSelectItem">
                <ProductImage
                    :image="slide?.image ? slide.image.image : slide.images[0]"
                    :new="slide.image?.new"
                    :offer="slide.image?.offer"
                    :classic="slide.image?.classic"
                    :special="slide.image?.special"
                    :organism="slide.image?.organism"
                    :sold-out="!isAvailable"
                    :productTitle="slide.title"
                    sizes="sm:320px md:460px lg:620px"
                />
            </NuxtLink>
        </div>
        <div class="d-flex align-items-start flex-column slide__content">
            <div class="slide__product">
                <div
                    class="equal-small-2 equal-small-2--bold-uppercase slide__type"
                >
                    {{ slide.type }}
                </div>
                <div class="slide__product-info">
                    <NuxtLink
                        :to="slide?.link?.to || '#'"
                        class="slide__title-link"
                        @click="handleSelectItem"
                    >
                        <h3
                            class="scale-2 scale-2--bold-uppercase slide__title"
                            :id="slugifyString(slide.title)"
                        >
                            {{ slide.title }}
                        </h3>
                    </NuxtLink>
                    <div
                        class="equal-base equal-base--tighter-bold-uppercase slide__info"
                        v-if="slide.info"
                    >
                        {{ slide.info }}
                    </div>
                </div>
                <Quotation
                    v-if="slide.quotation"
                    class="equal-base equal-base--tighter-serif slide__quotation"
                    :text="slide.quotation"
                />
            </div>
            <div class="d-flex flex-column w-100 mt-auto">
                <div class="d-flex flex-column">
                    <div
                        v-if="price?.action"
                        class="equal-base equal-base--tighter slide__price-invalid"
                    >
                        {{ price?.currency }}
                        {{ price?.original_incl_tax
                        }}<span v-if="slide.structure !== 'standalone'">
                            / 100g</span
                        >
                    </div>
                    <div
                        class="scale-1 scale-1--bold slide__price"
                        :class="{ 'slide__price--offer': price?.action }"
                    >
                        {{ price?.currency }}
                        {{
                            price?.action
                                ? price?.incl_tax
                                : price?.original_incl_tax
                        }}
                        <span v-if="slide.structure !== 'standalone'">
                            / 100g</span
                        >
                    </div>
                </div>
                <div class="d-flex slide__buttons">
                    <FormButton
                        class="align-self-end l-button--secondary l-button--outlined slide__link"
                        :label="t('product.teaser.discover')"
                        :to="slide.link?.to"
                        component="NuxtLink"
                    >
                    </FormButton>
                    <div
                        v-if="isAvailable"
                        class="align-self-end flex-shrink-0 slide__shopping-cart"
                    >
                        <ProductPicker
                            :product="product"
                            :availabilities="availabilities"
                            placement="bottom"
                            :backdrop="false"
                            :size40="false"
                            parent="teaser_detail"
                            :parent-title="seoStore.pageTitle"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useElementVisibility } from '~/composables/useElementVisibility';
import { useI18n } from 'vue-i18n';
import { slugifyString } from '~/utils/slugify';
import { useTrackSelectItem } from '~/composables/gtm/useEcomTracking';

const { t } = useI18n();

const props = defineProps({
    slide: {
        type: Object,
        required: true,
    },
    index: {
        type: Number,
        required: false,
    },
    parentType: {
        type: String,
        required: false,
    },
});

const teaser = ref(null);
const targetIsVisible = useElementVisibility(teaser);
const { loaded, loading, availabilities, success, isAvailable, parentPrice } =
    useAvailability(targetIsVisible, props.slide);

const product = computed(() => {
    return {
        ...props.slide,
    };
});

const price = computed(() => {
    if (parentPrice.value) {
        return parentPrice.value;
    }
    if (props.slide?.price?.value) {
        return props.slide.price;
    }
    if (availabilities.value.length > 0) {
        return availabilities.value[0];
    }
});

const seoStore = useSeoStore();

function handleSelectItem() {
    useTrackSelectItem(
        product.value,
        props.parentType,
        seoStore.pageTitle,
        props.index,
        price.value,
    );
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.slide {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: $color-dark-03;
    text-align: left;
    .product-image {
        overflow: hidden;
        :deep(.product-image__image) {
            transition: 0.5s ease;
        }
    }
    &:hover,
    &:focus,
    &:active {
        background-color: $color-dark-02;
        transition: 0.5s ease;
        .product-image:deep(.product-image__image) {
            transform: scale(1.05);
            transition: 0.5s ease;
        }
    }
    &--horizontal {
        display: flex;
        flex-direction: row !important;
        align-items: center;
        justify-content: space-between;

        .slide__image {
            width: 50%;
        }

        .slide__content {
            width: 50%;
        }
    }

    &___image {
        max-height: 213px;
        overflow: hidden;
    }

    &__content {
        height: 100%;
        padding: 25px;
    }

    &__product {
        width: 100%;
        margin-bottom: 20px;
    }

    &__type {
        color: $color-disabled;
        @include media-breakpoint-down(640) {
            margin-bottom: 1px;
        }
    }

    &__title {
        margin-right: 10px;
    }

    &__info {
        margin-top: 10px;
        color: $color-disabled;
    }

    &__quotation {
        margin-top: 25px;

        @include media-breakpoint-down(640) {
            margin-top: 20px;
        }
    }

    &__price {
        &--offer {
            color: #c62424;
        }
    }

    &__price-invalid {
        color: #999999;
        text-decoration: line-through;
    }

    &__buttons {
        display: flex;
    }

    &__link {
        margin-top: 25px;
        max-width: min(240px, calc(100% - 60px));
        min-width: unset;
        width: 100%;
    }

    &__shopping-cart {
        margin-left: 10px;
    }
}
</style>
